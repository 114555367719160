<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#CustomRangeModal"
      ref="openModalCustomRange"
    ></button>
    <div
      class="modal fade"
      id="CustomRangeModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="exampleModalLabel">Custom Range selection</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeRangeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="registry-sign-up-form-content">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 col-md-6 pl-md-0">
                        <div class="form-group">
                          <label for="startDate">Start Date</label>
                          <input
                            type="date"
                            :max="new Date()"
                            class="form-control"
                            id="startDate"
                            autocomplete="off"
                            v-model.trim="$v.details.start_date.$model"
                            :class="{
                              'is-invalid': $v.details.start_date.$error,
                              'is-valid': !$v.details.start_date.$invalid,
                            }"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.start_date.required"
                              >Start Date is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 px-md-0">
                        <div class="form-group">
                          <label for="endDate">End Date</label>
                          <input
                            type="date"
                            class="form-control"
                            id="endDate"
                            autocomplete="off"
                            v-model.trim="$v.details.end_date.$model"
                            :class="{
                              'is-invalid': $v.details.end_date.$error,
                              'is-valid': !$v.details.end_date.$invalid,
                            }"
                          />
                          <div class="invalid-feedback">
                            <span v-if="!$v.details.end_date.required"
                              >End Date is required</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="custom-btn2 float-right"
                @click="Filter(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Filter</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "CustomRangeModal",
  data: function() {
    return {
      details: {
        start_date: "",
        end_date: "",
      },
      isLoadingArray: [],
    };
  },
  validations: {
    details: {
      start_date: {
        required,
      },
      end_date: {
        required,
      },
    },
  },
  methods: {
    Filter: function(index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      setTimeout(() => {
        this.$root.$emit("dataDateFromModal", this.details);
            }, 500);
      
      this.$set(this.isLoadingArray, index, false);
      var elem = this.$refs.closeRangeModal;
      elem.click();
    },
    dateRestriction(){
      var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();

    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();

    var maxDate = year + '-' + month + '-' + day;
    document.getElementById("endDate").setAttribute('max', maxDate);
    document.getElementById("startDate").setAttribute('max', maxDate);
    }
  },
  mounted() {
    this.dateRestriction()
    this.$root.$on("openCustomRangeModal", () => {
      var elem = this.$refs.openModalCustomRange;
      elem.click();
    });
  },
};
</script>

<style></style>
