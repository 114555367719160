<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <Top />
        <StatementTable
          :displayedStatements="statement"
          :showLoader="showLoader"
          :totalCredit="totalCredit"
          :totalDedit="totalDedit"
        />
        <DatatableBottom :page="pager" :module="moduleName" :count="count" :filterTextRecieved="filterText" />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import Top from "../components/statements/StatementTop.vue";
import StatementTable from "../components/statements/StatementTable.vue";
import DatatableBottom from "../components/tableview/DatatableBottom.vue";
import axios from "axios";

export default {
  name: "BalanceStatment",

  components: {
    Navbar,
    LeftMenu,
    Top,
    StatementTable,
    DatatableBottom,
  },

  data: function() {
    return {
      moduleName: "",
      errorMessage: null,
      statement: [],
      showLoader: 0,
      page: 0,
      totalCredit:0,
      totalDedit:0,
      pager: {},
      perPage: 3,
      pages: [],
      success: null,
      error: null,
      count: 0,
      filter: {},
      filterText: "Select",

    };
  },
  watch: {
    "$route.query.page": {
      immediate: true,
      handler(page) {
        page = parseInt(page) || 1;
        if (page !== this.pager.current_page) {
          this.showLoader = 1;
          this.fetchStatement(page);
        }
      },
    },
  },

  methods: {
    fetchStatement(page) {
      axios
        .post(`${process.env.VUE_APP_API_URL}statement/balanceStatement?page=${
                page ? page : ""
              }&size=10&end_date=${
                this.filter.endDate ? this.filter.endDate : ""
              }&start_date=${this.filter.startDate ? this.filter.startDate : ""}&supplier_id=${
                this.filter.supplier_id ? this.filter.supplier_id : ""
              }&customer_id=${
                this.filter.customer_id ? this.filter.customer_id : ""
              }&manufacturer_id=${
                this.filter.manufacturer_id ? this.filter.manufacturer_id : ""
              }
`, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            console.log(response.data.data)
            this.statement = response.data.data;

            if(this.statement.length>0)
            {
              this.statement.forEach(element => {
                if(element.statement_status==1)
                {
                    this.totalDedit=this.totalDedit+element.amount;
                }
                else
                {
                    this.totalDedit=this.totalDedit+element.amount; 
                }
              });
            }
            this.moduleName =
              response.data.data.total > 0 ? "statement" : "statement";
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
  },

  mounted() {
    this.$root.$on("filtersCall", (data, filterText) => {
      this.filter = data;
      this.filterText = filterText;
      this.fetchStatement(1);
    });

  },
};
</script>
