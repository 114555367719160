<template>
  <div>
    <table class="table table-responsive-md table-sm">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">Sr No.</th>
            <th scope="col" class="text-center">Date / Week</th>
          <th scope="col" class="text-center">Purchase Reference</th>
          <th scope="col" class="text-center">User Type</th>
          <th scope="col" class="text-center">User</th>
          <th scope="col" class="text-center">Product</th>
          <th scope="col" class="text-center">Amount ({{this.$currency}})</th>
           <th scope="col" class="text-center">Status</th>
          <!-- <th scope="col" class="text-center">Actions</th> -->
        </tr>
      </thead>

      <tbody v-if="showLoader == 1">
        <tr class="shimmer-td">
          <td colspan="9">
            <content-placeholders class="shimmer" :rounded="true">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedStatements.length > 0">
        <tr v-for="(statement, index) in displayedStatements" :key="index">
          <td>
            <div class="company-link">
              <span>S-{{ index + 1 }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ statement.dateFormat }} <br/><b>Week:</b> {{ statement.week }}</span>
            </div>
          </td>
          <td>
            <div class="company-link">
              <span>{{ statement.purchase_reference }}</span>
            </div>
          </td>
          <td>
            <div class="company-size">
              <span><b>{{ statement.user_type }}</b></span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span><b>{{ statement.user_name }}</b> <br/>{{ statement.email }} <br/> {{ statement.phone }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ statement.detailsProduct }}</span>
            </div>
          </td>
         
          <td>
            <div class="company-location">
              <span style="color:green;"><b>{{ statement.amount }}</b></span>
            </div>
          </td>
          <td v-if="statement.statement_status == 2" class="text-center">
            <button class="active">Credit</button>
          </td>
          <td v-else-if="statement.statement_status == 1" class="text-center">
            <button class="terminated">Debit </button>
          </td>
          <!-- <td>
            <div class="company-location">
              <span></span>
            </div>
          </td> -->
          
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedStatements.length == 0">
        <tr class="no-data">
          <td colspan="9" class="shimmer">
            No Data Found.
          </td>
        </tr>
      </tbody>
    </table>

     <div class="row justify-content-md-end" v-if="showLoader == 0 && displayedStatements.length > 0">
              <div class="col-md-4">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tr>
                      <th>Total Debit</th>
                      <td>(-) {{totalDedit}} {{this.$currency}}</td>
                    </tr>
                    <tr>
                      <th>Total Credit</th>
                      <td>(+) {{totalCredit}} {{this.$currency}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

  </div>
</template>

<script>
export default {
  name: "StatementTable",
  props: {
    displayedStatements: Array,
    showLoader: Number,
    totalCredit: Number,
    totalDedit: Number,
  },
  data() {
    return {
    };
  },

  methods: {
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
