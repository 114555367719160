<template>
  <div class="debit-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mb-3">
          <router-link to="/payment-payable" class="custom-btn4 float-right"
            >Back</router-link
          >
        </div>
        <div class="col-12 col-lg-6 pl-0 pr-md-2">
          <div class="company-detail">
            <div class="company-logo">
              <img src="../../assets/images/company-logo.png" alt="" />
            </div>
            <h5 class="copmany-name">Zylker India</h5>
            <h6 class="address">44 Annasalai Chennai 60002, india</h6>
            <div class="company-para">
              <p><span>GSTN:</span> <span>33GSPTN0371G1ZD</span></p>
              <p><span>Phone:</span> <span>03001234567</span></p>
              <p><span>PAN:</span> <span>33GSPTN0371G1ZD</span></p>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 px-0 text-right">
          <div class="debit-detail">
            <h3>Debit Note</h3>
            <p class="id">Debit Note# CDN-000004</p>
            <div class="debit-balance">
              <span>Balance Due</span>
              <span>$103.00</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 pl-0 pr-md-2">
          <div class="bill-detail">
            <h5>Bill To</h5>
            <h6 class="address">
              <span>Ambit Depots,</span><br />
              <span>10 Thukar Street, Chennai 600450</span><br />
              <span>Tamil Nadu, India</span>
            </h6>
            <div class="company-para">
              <p><span>GSTN:</span> <span>33GSPTN0371G1ZD</span></p>
              <p><span>Phone:</span> <span>03001234567</span></p>
              <p><span>Circuit ID:</span> <span>33GSPTN0371G1ZD</span></p>
            </div>
          </div>
          <div class="supply-place">
            <p><span>Place of supply</span> <span>Tami Nadu (33)</span></p>
          </div>
        </div>
        <div class="col-12 col-lg-6 pl-0 pr-md-2">
          <div class="debit-info">
            <table class="table table-sm w-auto">
              <tbody>
                <tr>
                  <td class="border-0">
                    <div class="company-link">
                      <span>Debit Note Date:</span>
                    </div>
                  </td>
                  <td class="border-0">
                    <div class="company-link">
                      <span>05 Dev 2022</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="border-0">
                    <div class="company-link">
                      <span>Terms:</span>
                    </div>
                  </td>
                  <td class="border-0">
                    <div class="company-link">
                      <span>Due on Receipt</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="border-0">
                    <div class="company-link">
                      <span>Due Date:</span>
                    </div>
                  </td>
                  <td class="border-0">
                    <div class="company-link">
                      <span>05 Dev 2022</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="border-0">
                    <div class="company-link">
                      <span>Reference Invoice#:</span>
                    </div>
                  </td>
                  <td class="border-0">
                    <div class="company-link">
                      <span>05 Dev 2022</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="border-0">
                    <div class="company-link">
                      <span>Reference Invoice Date:</span>
                    </div>
                  </td>
                  <td class="border-0">
                    <div class="company-link">
                      <span>05 Dev 2022</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <table class="table table-sm debit-table">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">#</th>
          <th scope="col" class="text-center">Item & Description</th>
          <th scope="col" class="text-center">Rate</th>
          <th scope="col" class="text-center">Cost</th>
          <th scope="col" class="text-center">SGST</th>
          <th scope="col" class="text-center">Amount</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <div class="company-link">
              <span>C-10213</span>
            </div>
          </td>
          <td>
            <div class="company-link">
              <span>Bilaal</span>
            </div>
          </td>
          <td>
            <div class="company-size">
              <span>13244234234</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>Lahore</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>Pakistan</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>davis road</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="debit-info">
      <table class="table table-sm w-auto">
        <tbody>
          <tr>
            <td class="border-0 text-right">
              <div class="company-link">
                <span>Sub Total:</span>
              </div>
            </td>
            <td class="border-0">
              <div class="company-link">
                <span>05 Dev 2022</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="border-0 text-right">
              <div class="company-link">
                <span>CGST 1.5 (1.5%):</span>
              </div>
            </td>
            <td class="border-0">
              <div class="company-link">
                <span>Due on Receipt</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="border-0 text-right">
              <div class="company-link">
                <span>SGST 1.5 (1.5%):</span>
              </div>
            </td>
            <td class="border-0">
              <div class="company-link">
                <span>05 Dev 2022</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="border-0 text-right">
              <div class="company-link">
                <span>Total:</span>
              </div>
            </td>
            <td class="border-0">
              <div class="company-link">
                <span>05 Dev 2022</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="border-0 text-right">
              <div class="company-link">
                <span>Balance Due:</span>
              </div>
            </td>
            <td class="border-0">
              <div class="company-link">
                <span>05 Dev 2022</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "DebitDetail",
};
</script>

<style></style>
