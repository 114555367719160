<template>
  <div>
    <CustomRangeModal />
    <h5 class="mb-2">Balance Statement</h5>
    <div class="row align-items-center">
      <div class="col-3 p-0 pr-2 mt-2">
        <div class="form-group">
          <label for="supplier_id">
            Manufacturers
          </label>
          <select
            class="form-control pl-1"
            v-model="filter.manufacturer_id"
            name="manufacturer_id"
            id="manufacturer_id"
            @change="getManuSelectionCalled($event)"
          >
            <option value selected>Select Manufacturer</option>
            <option
              v-for="item in manufacturerOptions"
              :key="item.id"
              :value="item.id"
            >{{ item.manufacturer_name }}</option>
          </select>
        </div>
      </div>
      <div class="col-3 p-0 pr-2 mt-2">
        <div class="form-group">
          <label for="supplier_id">
            Suppliers
          </label>
          <select
            class="form-control pl-1"
            name="supplier_id"
            v-model="filter.supplier_id"
            @change="getSupSelectionCalled($event)"
            id="supplier_id"
          >
            <option value selected disabled>Select Supplier</option>
            <option
              v-for="item in supplierOptions"
              :key="item.id"
              :value="item.id"
            >{{ item.supplier_name }}</option>
          </select>
        </div>
      </div>
      <div class="col-3 p-0 mt-2">
        <div class="form-group">
            <label for="customer_id">
              Customers
            </label>
            <select
              class="form-control pl-1"
              name="customer_id"
              v-model="filter.customer_id"
              id="customer_id"
              @change="getCustSelectionCalled($event)"
            >
              <option value selected disabled>Select Customers</option>
              <option
                v-for="item in customersOptions"
                :key="item.id"
                :value="item.id"
              >{{ item.customer_name }}</option>
            </select>
        </div>
      </div>
      <div class="col-3 p-0 pr-2 mt-4">
        <div class="row">
          <div class="col-lg-6 col-sm-3">
            <div class="row">
            <button class="custom-btn2 float-right">Date</button>
            <div class="">
              <button
                @click="openDateFilter"
                v-click-outside="dateFilterOutside"
                class="custom-btn2"
              >
                <img src="../../assets/images/dropdown.png" alt />
              </button>
              <div v-if="dateFilter" id="overlay">
                <div id="myDropdown" class="dropdown-content">
                  <a href="#today" @click="getFilters('today')">
                    <img src="../../assets/images/calender.png" alt />
                    <div class="date-content">
                      <div class="range">Today</div>
                      <div class="date">{{ new Date() | moment("MMM D") }}</div>
                    </div>
                  </a>
                  <div class="line-seperator my-0"></div>
                  <a href="#yesterday" @click="getFilters('yesterday')">
                    <img src="../../assets/images/calender.png" alt />
                    <div class="date-content">
                      <div class="range">Yesterday</div>
                      <div class="date">{{ dateYesterday | moment("MMM D") }}</div>
                    </div>
                  </a>
                  <div class="line-seperator my-0"></div>
                  <a href="#lastweek" @click="getFilters('lastweek')">
                    <img src="../../assets/images/calender.png" alt />
                    <div class="date-content">
                      <div class="range">Last Week</div>
                      <div class="date">
                        {{ dateEndWeek | moment("MMM D") }} -
                        {{ dateStartWeek | moment("D") }}
                      </div>
                    </div>
                  </a>
                  <div class="line-seperator my-0"></div>
                  <a href="#lastmonth" @click="getFilters('lastmonth')">
                    <img src="../../assets/images/calender.png" alt />
                    <div class="date-content">
                      <div class="range">Last Month</div>
                      <div class="date">
                        {{ dateEndMonth | moment("MMM D") }} -
                        {{ dateStartMonth | moment("MMM D") }}
                      </div>
                    </div>
                  </a>
                  <div class="line-seperator my-0"></div>
                  <a href="#lastmonth" @click="callRangeModal()">
                    <img src="../../assets/images/calender.png" alt />
                    <div class="date-content">
                      <div class="range">Custom Range</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            </div>
            
          </div>
          <div class="col-4 p-0 pr-2">
            <button class="custom-btn2 float-left" @click="clearFilter()">Clear Filter</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import CustomRangeModal from "./customeRangeModal.vue";
export default {
  name: "CustomerTop",
  props: {
    displayedAgreement: Array,
    page: Object,
    filterTextRecieved: String,
    searched: String
  },
  components: {
    CustomRangeModal
  },

  data: function() {
    return {
      supplierOptions: [],
      manufacturerOptions: [],
      customersOptions: [],
      customerFilter: false,
      isLoadingArray: [],
      dateFilter: false,
      rowsFilter: false,
      filterText: "Select",
      dateToday: "",
      dateYesterday: "",
      dateStartWeek: "",
      dateEndWeek: "",
      dateStartMonth: "",
      dateEndMonth: "",
      filter: {
        startDate: "",
        endDate: "",
        supplier_id: "",
        manufacturer_id: "",
        customer_id: ""
      }
    };
  },
  methods: {
    clearFilter() {
      this.filter = {};
      this.$root.$emit("filtersCall", this.filter, "Select");
    },

    openCustomerFilter: function() {
      this.customerFilter = !this.customerFilter;
    },
    openDateFilter: function() {
      this.dateFilter = !this.dateFilter;
    },
    dateFilterOutside: function() {
      this.dateFilter = false;
    },

    openRowsFilter: function() {
      this.rowsFilter = !this.rowsFilter;
    },

    rowsFilterOutside: function() {
      this.rowsFilter = false;
    },

    closeCustomerFilter: function() {
      this.customerFilter = false;
    },
    filterFunction() {
      var input, filter, a, i, div, txtValue;
      filter = input.value.toUpperCase();
      div = document.getElementById("myDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    getFilters(value) {
      if (value === "today") {
        this.filterText = "Today";
        const today = new Date();
        this.filter.startDate = moment(today, "YYYY/MM/DD").format(
          "YYYY-MM-DD"
        );
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "yesterday") {
        this.filterText = "Yesterday";
        const today = new Date();
        today.setDate(today.getDate() - 1);
        this.filter.startDate = moment(today, "YYYY/MM/DD").format(
          "YYYY-MM-DD"
        );
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "lastweek") {
        this.filterText = "Last Week";
        var week = new Array();
        let current = moment().subtract(1, "days");
        for (var i = 0; i < 7; i++) {
          week.push(current.format("YYYY-MM-DD"));
          current = current.subtract(1, "days");
        }
        this.filter.startDate = week[0];
        this.filter.endDate = week[6];
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "lastmonth") {
        this.filterText = "Last Month";
        var month = new Array();
        let current = moment().subtract(1, "days");
        for (var j = 0; j < 30; j++) {
          month.push(current.format("YYYY-MM-DD"));
          current = current.subtract(1, "days");
        }
        this.filter.startDate = month[0];
        this.filter.endDate = month[29];
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      } else if (value === "Custom") {
        this.filterText = "Custom";
        this.$root.$emit("filtersCall", this.filter, this.filterText);
      }
    },
    getManuSelectionCalled(e) {
      this.filter.manufacturer_id = e.target.value;
      this.$root.$emit("filtersCall", this.filter, this.filterText);
    },
    getSupSelectionCalled(e) {
      this.filter.supplier_id = e.target.value;
      this.$root.$emit("filtersCall", this.filter, this.filterText);
    },
    getCustSelectionCalled(e) {
      this.filter.customer_id = e.target.value;
      this.$root.$emit("filtersCall", this.filter, this.filterText);
    },
    filterRows() {
      var input, filter, a, i, div, txtValue;
      filter = input.value.toUpperCase();
      div = document.getElementById("rowsDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    getSupplier() {
      axios
        .get(`${process.env.VUE_APP_API_URL}supplier/getSupplier`)
        .then(response => {
          this.supplierOptions = [];
          if (response.data.statusCode == 200) {
            this.supplierOptions = response.data.data;
          }
        });
    },
    getCustomers() {
      axios
        .get(`${process.env.VUE_APP_API_URL}customer/getCustomer`)
        .then(response => {
          this.customersOptions = [];
          if (response.data.statusCode == 200) {
            this.customersOptions = response.data.data;
          }
        });
    },
    getManufacturer() {
      axios
        .get(`${process.env.VUE_APP_API_URL}manufacturer/getManufacturer`)
        .then(response => {
          this.manufacturerOptions = [];
          if (response.data.statusCode == 200) {
            this.manufacturerOptions = response.data.data;
          }
        });
    },
    callRangeModal() {
      this.$root.$emit("openCustomRangeModal");
    },
    datesGet() {
      this.filter.search = this.searched;
      const today = new Date();
      //today
      this.dateToday = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
      //yesterday
      today.setDate(today.getDate() - 1);
      this.dateYesterday = moment(today, "YYYY/MM/DD").format("YYYY-MM-DD");
      //week
      var week = new Array();
      let current = moment().subtract(1, "days");
      for (var i = 0; i < 7; i++) {
        week.push(current.format("YYYY-MM-DD"));
        current = current.subtract(1, "days");
      }
      this.dateStartWeek = week[0];
      this.dateEndWeek = week[6];
      //month
      var month = new Array();
      for (var j = 0; j < 30; j++) {
        month.push(current.format("YYYY-MM-DD"));
        current = current.subtract(1, "days");
      }
      this.dateStartMonth = month[0];
      this.dateEndMonth = month[29];
    }
  },
  mounted() {
    this.datesGet();
    this.getManufacturer();
    this.getSupplier();
    this.getCustomers();
    console.log(this.displayedAgreement);
    this.$root.$on("dataDateFromModal", data => {
      this.filter.startDate = data.start_date;
      this.filter.endDate = data.end_date;
      this.getFilters("Custom");
    });
  }
};
</script>

<style></style>
