var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"debit-wrapper"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('router-link',{staticClass:"custom-btn4 float-right",attrs:{"to":"/payment-payable"}},[_vm._v("Back")])],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)])]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 pl-0 pr-md-2"},[_c('div',{staticClass:"company-detail"},[_c('div',{staticClass:"company-logo"},[_c('img',{attrs:{"src":require("../../assets/images/company-logo.png"),"alt":""}})]),_c('h5',{staticClass:"copmany-name"},[_vm._v("Zylker India")]),_c('h6',{staticClass:"address"},[_vm._v("44 Annasalai Chennai 60002, india")]),_c('div',{staticClass:"company-para"},[_c('p',[_c('span',[_vm._v("GSTN:")]),_vm._v(" "),_c('span',[_vm._v("33GSPTN0371G1ZD")])]),_c('p',[_c('span',[_vm._v("Phone:")]),_vm._v(" "),_c('span',[_vm._v("03001234567")])]),_c('p',[_c('span',[_vm._v("PAN:")]),_vm._v(" "),_c('span',[_vm._v("33GSPTN0371G1ZD")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 px-0 text-right"},[_c('div',{staticClass:"debit-detail"},[_c('h3',[_vm._v("Debit Note")]),_c('p',{staticClass:"id"},[_vm._v("Debit Note# CDN-000004")]),_c('div',{staticClass:"debit-balance"},[_c('span',[_vm._v("Balance Due")]),_c('span',[_vm._v("$103.00")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 pl-0 pr-md-2"},[_c('div',{staticClass:"bill-detail"},[_c('h5',[_vm._v("Bill To")]),_c('h6',{staticClass:"address"},[_c('span',[_vm._v("Ambit Depots,")]),_c('br'),_c('span',[_vm._v("10 Thukar Street, Chennai 600450")]),_c('br'),_c('span',[_vm._v("Tamil Nadu, India")])]),_c('div',{staticClass:"company-para"},[_c('p',[_c('span',[_vm._v("GSTN:")]),_vm._v(" "),_c('span',[_vm._v("33GSPTN0371G1ZD")])]),_c('p',[_c('span',[_vm._v("Phone:")]),_vm._v(" "),_c('span',[_vm._v("03001234567")])]),_c('p',[_c('span',[_vm._v("Circuit ID:")]),_vm._v(" "),_c('span',[_vm._v("33GSPTN0371G1ZD")])])])]),_c('div',{staticClass:"supply-place"},[_c('p',[_c('span',[_vm._v("Place of supply")]),_vm._v(" "),_c('span',[_vm._v("Tami Nadu (33)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 pl-0 pr-md-2"},[_c('div',{staticClass:"debit-info"},[_c('table',{staticClass:"table table-sm w-auto"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Debit Note Date:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])]),_c('tr',[_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Terms:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Due on Receipt")])])])]),_c('tr',[_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Due Date:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])]),_c('tr',[_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Reference Invoice#:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])]),_c('tr',[_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Reference Invoice Date:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table table-sm debit-table"},[_c('thead',{staticClass:"dark"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Item & Description")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Rate")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Cost")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("SGST")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Amount")])])]),_c('tbody',[_c('tr',[_c('td',[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("C-10213")])])]),_c('td',[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Bilaal")])])]),_c('td',[_c('div',{staticClass:"company-size"},[_c('span',[_vm._v("13244234234")])])]),_c('td',[_c('div',{staticClass:"company-location"},[_c('span',[_vm._v("Lahore")])])]),_c('td',[_c('div',{staticClass:"company-location"},[_c('span',[_vm._v("Pakistan")])])]),_c('td',[_c('div',{staticClass:"company-location"},[_c('span',[_vm._v("davis road")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"debit-info"},[_c('table',{staticClass:"table table-sm w-auto"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"border-0 text-right"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Sub Total:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])]),_c('tr',[_c('td',{staticClass:"border-0 text-right"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("CGST 1.5 (1.5%):")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Due on Receipt")])])])]),_c('tr',[_c('td',{staticClass:"border-0 text-right"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("SGST 1.5 (1.5%):")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])]),_c('tr',[_c('td',{staticClass:"border-0 text-right"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Total:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])]),_c('tr',[_c('td',{staticClass:"border-0 text-right"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("Balance Due:")])])]),_c('td',{staticClass:"border-0"},[_c('div',{staticClass:"company-link"},[_c('span',[_vm._v("05 Dev 2022")])])])])])])])
}]

export { render, staticRenderFns }